// date-formatter.service.ts
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  constructor() {}

  /**
   * Formats a date according to the local format
   * @param date - Date to format (can be Date object, timestamp, or date string)
   * @param format - Optional format string (defaults to local date format)
   * @param locale - Optional locale string (defaults to browser's locale)
   * @returns Formatted date string
   */
  formatToLocalDate(date: Date | string | number, format?: string, locale?: string): string {
    try {
      // Get browser's locale or use provided locale
      const userLocale = locale || navigator.language || 'en-US';
      
      // Default format string based on locale convention
      const defaultFormat = new Intl.DateTimeFormat(userLocale).resolvedOptions().locale.startsWith('en') 
        ? 'MMM d, y h:mm a'  // US format
        : 'd MMM y HH:mm';   // Most other locales
      
      return formatDate(date, format || defaultFormat, userLocale);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  }

  /**
   * Formats a date to show either today's time or full date
   * @param date - Date to format
   * @param locale - Optional locale string
   * @returns Formatted date string
   */
  formatSmartDate(date: Date | string | number, locale?: string): string {
    try {
      const dateObj = new Date(date);
      const now = new Date();
      const userLocale = locale || navigator.language || 'en-US';

      // Check if date is today
      const isToday = dateObj.getDate() === now.getDate() &&
                     dateObj.getMonth() === now.getMonth() &&
                     dateObj.getFullYear() === now.getFullYear();

      if (isToday) {
        return `${formatDate(dateObj, 'h:mm a', userLocale)} Today`;
      } else {
        return this.formatToLocalDate(dateObj, undefined, userLocale);
      }
    } catch (error) {
      console.error('Error formatting smart date:', error);
      return 'Invalid Date';
    }
  }

  /**
   * Gets relative time (e.g., "2 hours ago", "in 3 days")
   * @param date - Date to compare
   * @param locale - Optional locale string
   * @returns Relative time string
   */
  getRelativeTime(date: Date | string | number, locale?: string): string {
    try {
      const dateObj = new Date(date);
      const now = new Date();
      const userLocale = locale || navigator.language || 'en-US';

      const rtf = new Intl.RelativeTimeFormat(userLocale, { numeric: 'auto' });
      const diffInSeconds = (dateObj.getTime() - now.getTime()) / 1000;

      // Convert to appropriate unit
      if (Math.abs(diffInSeconds) < 60) {
        return rtf.format(Math.round(diffInSeconds), 'second');
      } else if (Math.abs(diffInSeconds) < 3600) {
        return rtf.format(Math.round(diffInSeconds / 60), 'minute');
      } else if (Math.abs(diffInSeconds) < 86400) {
        return rtf.format(Math.round(diffInSeconds / 3600), 'hour');
      } else {
        return rtf.format(Math.round(diffInSeconds / 86400), 'day');
      }
    } catch (error) {
      console.error('Error getting relative time:', error);
      return 'Invalid Date';
    }
  }
}

// // Example Component Usage:
// // date-demo.component.ts
// import { Component } from '@angular/core';
// import { DateFormatterService } from './date-formatter.service';

// @Component({
//   selector: 'app-date-demo',
//   template: `
//     <div class="date-examples">
//       <h3>Date Formatting Examples</h3>
      
//       <div class="example">
//         <h4>Local Date Format:</h4>
//         <p>{{ formattedLocalDate }}</p>
//       </div>

//       <div class="example">
//         <h4>Smart Date Format:</h4>
//         <p>{{ formattedSmartDate }}</p>
//       </div>

//       <div class="example">
//         <h4>Relative Time:</h4>
//         <p>{{ relativeTime }}</p>
//       </div>
//     </div>
//   `,
//   styles: [`
//     .date-examples {
//       padding: 20px;
//     }
//     .example {
//       margin-bottom: 20px;
//     }
//   `]
// })
// export class DateDemoComponent {
//   formattedLocalDate: string;
//   formattedSmartDate: string;
//   relativeTime: string;

//   constructor(private dateFormatter: DateFormatterService) {
//     const date = new Date();
    
//     this.formattedLocalDate = this.dateFormatter.formatToLocalDate(date);
//     this.formattedSmartDate = this.dateFormatter.formatSmartDate(date);
//     this.relativeTime = this.dateFormatter.getRelativeTime(date);
//   }
// }