import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClipboardModule } from 'ngx-clipboard';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, APIService, SSEService, LayoutService, StateService, GoogleAnalyticsService, DownloadService } from './_services';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CommonModule } from '@angular/common';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { AngularNotifierModule } from './shared/modules/angular-notifier/angular-notifier.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { SharedDialog } from './shared/dialogs/shared.dialogs';
import { RouterModule } from "@angular/router";
import { SharedModule } from './shared/modules/shared.module';
import { DateFormatterService } from './_services/date.formatter.service';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        HttpClientModule,
        CommonModule,
        LanguageTranslationModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ColorPickerModule,
        ClipboardModule,
        AngularNotifierModule,
        SharedDialog,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        DragulaModule.forRoot(),
        RouterModule,
        SharedModule,
        routing,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        GoogleAnalyticsService,
        AuthGuard,
        AlertService,
        AuthenticationService,
        APIService,
        SSEService,
        LayoutService,
        StateService,
        DownloadService,
        DateFormatterService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
}